<template>
  <div>
    <v-dialog
      v-model="$store.state.dialog"
      width="50%"
      :fullscreen="isfullscreen"
      persistent
      transition="dialog-bottom-transition"
    >
      <v-card flat class="px-4 pt-4">
        <!-- dialog toolbar -->
        <v-toolbar height="45" flat>
          <h5>إضافة {{ this.$route.meta.single }}</h5>
          <v-spacer />
          <v-btn small icon fab @click="isfullscreen = !isfullscreen">
            <v-icon small>{{
              isfullscreen ? "mdi-arrow-collapse" : "mdi-arrow-expand"
            }}</v-icon>
          </v-btn>
          <v-btn @click="close()" fab small icon color="grey">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider class="mx-4" />

        <!-- form body -->
        <v-form @submit.prevent="submit()" ref="form" lazy-validation v-model="valid">
          <v-container>
            <v-card flat class="py-4 px-1">
              <v-row>
                <!-- fullName -->
                <v-col cols="12" md="6">
                  <h6 class="mb-1">الاسم الكامل <span class="required">*</span></h6>
                  <v-text-field
                    v-model="body.fullName"
                    :rules="[$global.state.required()]"
                    required
                    outlined
                    hide-details="auto"
                    dense
                    filled
                    placeholder="الاسم الكامل"
                  >
                  </v-text-field>
                </v-col>

                <!-- userName -->
                <v-col cols="12" md="6">
                  <h6 class="mb-1">إسم المستخدم<span class="required">*</span></h6>
                  <v-text-field
                    v-model="body.userName"
                    :rules="[enRules(body.userName)]"
                    required
                    outlined
                    hide-details="auto"
                    dense
                    filled
                    hint="يجب إدخال إسم المستخدم بالإنكليزي"
                    placeholder="إسم المستخدم ( بالإنكليزي )"
                  >
                  </v-text-field>
                </v-col>

                <!-- phoneNumber -->
                <v-col cols="12" md="6">
                  <h6 class="mb-1">رقم الهاتف</h6>
                  <v-text-field
                    v-model="body.phoneNumber"
                    v-mask="$phoneMask"
                    dir="ltr"
                    placeholder="+964 ### ### ####"
                    filled
                    dense
                    outlined
                    hide-details="auto"
                  />
                </v-col>

                <!-- email -->
                <!-- <v-col cols="12" md="6">
                                    <h6 class="mb-1">البريدالإلكتروني</h6>
                                    <v-text-field v-model="body.email" :rules="[
                                        $global.state.required(),
                                        $global.state.emailFormat(),
                                    ]" outlined hide-details="auto" dense filled placeholder="البريدالإلكتروني">
                                    </v-text-field>
                                </v-col> -->

                <!-- password -->
                <v-col
                  cols="12"
                  md="6"
                  v-if="$store.state.itemDetails.dialogType == 'add'"
                >
                  <h6 class="mb-1">
                    كلمة المرور
                    <span class="required">*</span>
                  </h6>
                  <v-text-field
                    v-model="body.password"
                    placeholder="كلمة المرور"
                    :rules="[$global.state.passwordFormat()]"
                    required
                    filled
                    dense
                    outlined
                    :type="showPassword ? 'text' : 'password'"
                    :prepend-inner-icon="showPassword ? 'mdi-eye' : 'mdi-eye-closed'"
                    @click:prepend-inner="showPassword = !showPassword"
                    hide-details="auto"
                  />
                </v-col>
                <!--confirm password -->
                <v-col
                  cols="12"
                  md="6"
                  v-if="$store.state.itemDetails.dialogType == 'add'"
                >
                  <h6 class="mb-1">
                    تأكيد كلمة المرور
                    <span class="required">*</span>
                  </h6>
                  <v-text-field
                    :prepend-inner-icon="showPassword ? 'mdi-eye' : 'mdi-eye-closed'"
                    @click:prepend-inner="showPassword = !showPassword"
                    v-model="body.confirmPassword"
                    placeholder="تأكيد كلمة المرور"
                    :type="showPassword ? 'text' : 'password'"
                    :rules="[
                      body.password == body.confirmPassword || 'كلماة المرور غير متشابه',
                    ]"
                    required
                    filled
                    dense
                    outlined
                    hide-details="auto"
                  />
                </v-col>
                <!-- provinceId -->
                <v-col cols="12" md="6">
                  <h6 class="mb-1">المحافظة <span class="required">*</span></h6>
                  <v-autocomplete
                    v-model="body.provinceId"
                    item-text="name"
                    item-value="id"
                    :items="$global.state.provinces"
                    clearable
                    dense
                    filled
                    :rules="[$global.state.required()]"
                    placeholder="المحافظة"
                    no-data-text="لا توجد بيانات"
                    hide-details
                    outlined
                  />
                </v-col>
                <!-- user type -->
                <v-col cols="12" md="6">
                  <h6 class="mb-1">نوع المتسخدم <span class="required">*</span></h6>
                  <v-autocomplete
                    v-model="body.type"
                    item-text="text"
                    item-value="value"
                    :items="userType"
                    clearable
                    dense
                    filled
                    :rules="[$global.state.required()]"
                    placeholder="نوع المستخدم"
                    no-data-text="لا توجد بيانات"
                    hide-details
                    outlined
                  />
                </v-col>
                <!-- addres -->
                <v-col cols="12" md="6">
                  <h6 class="mb-1">العنوان الكامل <span class="required">*</span></h6>
                  <v-text-field
                    v-model="body.addres"
                    outlined
                    hide-details="auto"
                    dense
                    filled
                    placeholder="العنوان الكامل"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <h6 class="mb-1">المستلم المعتمد <span class="required">*</span></h6>
                  <v-text-field
                    v-model="body.authorizedRecipient"
                    outlined
                    hide-details="auto"
                    dense
                    filled
                    placeholder="المستلم المعتمد"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <h6 class="mb-1">رقم الهاتف للمستلم المعتمد</h6>
                  <v-text-field
                    v-model="body.authorizedRecipientPhoneNumber"
                    v-mask="$phoneMask"
                    dir="ltr"
                    placeholder="+964 ### ### ####"
                    filled
                    dense
                    outlined
                    hide-details="auto"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <h6 class="mb-1">تكلفة التحويل <span class="required">*</span></h6>
                  <v-text-field
                    v-model="body.conversionCost"
                    outlined
                    hide-details="auto"
                    dense
                    filled
                    placeholder="تكلفة التحويل"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <h6 class="mb-1">نسبة الربح <span class="required">*</span></h6>
                  <v-text-field
                    v-model="body.profitPercentage"
                    outlined
                    hide-details="auto"
                    dense
                    filled
                    placeholder="نسبة الربح"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <h6 class="mb-1">تاريخ الأستحقاق <span class="required">*</span></h6>
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateText"
                        placeholder="تاريخ الأستحقاق "
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        dense
                        filled
                        no-data-text="لا توجد بيانات"
                        hide-details
                        clearable
                        @click:clear="clearDate()"
                        outlined
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="body.dueDate"
                      :active-picker.sync="activePicker"
                      min="1950-01-01"
                      @input="save(body.dueDate)"
                      color="primary"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-divider class="ml- mt-8" />
              <!-- actions -->
              <div class="mt-4">
                <v-btn
                  type="submit"
                  :loading="$global.state.loading"
                  color="primary"
                  class="btn"
                  :disabled="!valid"
                >
                  حفظ
                  <v-icon small right>fi fi-rr-disk</v-icon>
                </v-btn>
                <v-btn @click="close()" text depressed class="mr-2 btn">إلغاء</v-btn>
              </div>
            </v-card>
          </v-container>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "Add",
  data: () => ({
    userType: [
      { text: "شركاء", value: 2 },
      { text: "مستخدمين", value: 1 },
    ],
    showPassword: false,
    isfullscreen: false,
    valid: true,
    dialog: true,
    body: {
      fullName: "",
      userName: "",
      // email: "",
      imageUrl: "",
      password: "",
      confirmPassword: "",
      phoneNumber: "",
      provinceId: null,
      addres: "",
      type: 0,
      authorizedRecipient: null,
      dueDate: null,
      authorizedRecipientPhoneNumber: null,
      conversionCost: 0,
      profitPercentage: 0,
      // groupId:"",
    },
    roles: [],
    activePicker: null,
    dateText: null,
  }),

  created() {
    this.reset();
    // this.getRoles();
    // if (this.$global.state.authGroups == "") this.getAuthGroups();
    this.$eventBus.$on("fill-fields", () => {
      this.body = this.$store.state.itemDetails;
      this.body.date = this.$store.state.itemDetails.date.substring(0, 10);
    });
  },

  methods: {
    enRules(v) {
      const englishRegex = /^[A-Za-z][A-Za-z0-9]*$/; // Regex to match only English letters and spaces
      if (!v) {
        return "هذا الحقل مطلوب"; // Arabic: This field is required
      } else if (!englishRegex.test(v)) {
        return "الرجاء إدخال حروف إنجليزية فقط و بدون فراغ"; // Arabic: Please enter only English characters
      }
      return true;
    },
    async getRoles() {
      this.$global.state.loading = true;
      try {
        const res = await this.$http.get(`AuthGroups/roles`);
        // console.log(res);
        this.roles = res.data.result;
      } catch (err) {
        console.log(err);
      } finally {
        this.$global.state.loading = false;
      }
    },
    reset() {
      this.body = {
        userName: "",
        // email: "",
        password: "",
        confirmPassword: "",
        phoneNumber: "",
        provinceId: null,
        addres: "",
        imageUrl: "",
        type: 0,
        authorizedRecipient: null,
        authorizedRecipientPhoneNumber: null,
        conversionCost: 0,
        profitPercentage: 0,
        dueDate: null,
      };
    },

    submit() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.addItem();
      }
    },

    async addItem() {
      this.$global.state.loading = true;
      this.body.imageUrl = "";
      await this.$http
        .post(`${this.$route.meta.endPoint}`, this.body)
        .then((res) => {
          this.$eventBus.$emit(`refresh`);
          this.$emit(`refresh`);
          this.close();
          this.$store.dispatch("toastification/setToast", {
            message: `${res.data.message}`,
            type: "success",
          });
        })
        .catch((err) => {
          console.log(err);

          var message = err.response.message ?? err.response.data.message;
          var result = err.response.data.result;
          this.$store.dispatch("toastification/setToast", {
            message: `${message}`,
            type: "error",
          });
          if (Array.isArray(result)) {
            result.forEach((element) => {
              this.$store.dispatch("toastification/setToast", {
                message: `${element.description}`,
                type: "error",
              });
            });
          }
        })
        .finally(() => (this.$global.state.loading = false));
    },

    async editItem() {
      this.$global.state.loading = true;
      await this.$http
        .put(`${this.$route.meta.endPoint}/${this.body.id}`, this.body)
        .then((res) => {
          this.$eventBus.$emit(`refresh`);
          this.close();
          this.$store.dispatch("toastification/setToast", {
            message: `${res.data.message}`,
            type: "success",
          });
        })
        .catch((err) => {
          this.$store.dispatch("toastification/setToast", {
            message: `${err.data.message}`,
            type: "error",
          });
        })
        .finally(() => (this.$global.state.loading = false));
    },
    save(date) {
      this.dateText = date;
      this.menu = false;
    },
    clearDate() {
      this.dateText = null;
      this.body.dueDate = null;
    },
    // getAuthGroups() {
    //     this.$global.dispatch(`getAuthGroups`);
    // },

    close() {
      this.$store.commit("setDialog");
      this.reset();
      this.resetValidation();
      this.$store.commit("setItemDetails", {});
    },

    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<style lang="scss" scoped></style>
